import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTokenInfo } from '../../../contexts';
import { Img, PairContainer, Placeholder } from './style';
export const TokenIcon = (props) => {
    const { tokenAddress, size = 24, className } = props;
    const tokenInfo = useTokenInfo(tokenAddress);
    const tokenLogo = tokenInfo === null || tokenInfo === void 0 ? void 0 : tokenInfo.logoURI;
    if (!tokenInfo) {
        return _jsx(Placeholder, Object.assign({ size: size }, { children: "?" }));
    }
    return (_jsx(Img, { "$bg": undefined, className: className, width: size, height: size, src: tokenLogo, alt: tokenAddress }));
};
export const PairIcons = (props) => {
    const { tokenAddressA, tokenAddressB, size = 24, className } = props;
    return (_jsxs(PairContainer, Object.assign({ size: size }, { children: [_jsx(TokenIcon, { tokenAddress: tokenAddressA, size: size, className: className }), _jsx(TokenIcon, { tokenAddress: tokenAddressB, size: size, className: className })] })));
};
