import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, forwardRef, useImperativeHandle, useCallback, useEffect, useState } from 'react';
import { ReactComponent as Circle } from './assets/circle.svg';
import { PieTimerCircleInside, PieTimerContainer, } from './styles';
export const PieTimer = forwardRef((params, ref) => {
    const { duration, size = 32, disabled, callbackFunction } = params;
    const animationResetter = useRef(null);
    const [resetTimerClickCounter, setResetTimerClickCounter] = useState(0);
    const restartAnimation = () => {
        if (animationResetter.current) {
            animationResetter.current.style.animationName = 'none';
            requestAnimationFrame(() => {
                setTimeout(() => {
                    if (animationResetter.current) {
                        animationResetter.current.style.animationName = '';
                    }
                }, 0);
            });
        }
    };
    const resetTimer = useCallback(() => {
        callbackFunction();
        restartAnimation();
    }, [callbackFunction]);
    const resetTimerClick = () => {
        callbackFunction();
        restartAnimation();
        setResetTimerClickCounter(resetTimerClickCounter + 1);
    };
    useEffect(() => {
        const resetTimer2 = () => {
            callbackFunction();
            restartAnimation();
        };
        if (!disabled) {
            const intervalId = setInterval(() => {
                resetTimer2();
            }, (duration * 1000) - 500);
            return () => {
                clearInterval(intervalId);
            };
        }
    }, [duration, disabled, callbackFunction, resetTimerClickCounter]);
    useImperativeHandle(ref, () => ({
        reset: resetTimer,
    }), [resetTimer]);
    return (_jsxs(PieTimerContainer, Object.assign({ "$size": size, "$duration": duration, "$disabled": disabled, onClick: () => {
            resetTimerClick();
        } }, { children: [_jsx("div", { ref: animationResetter, className: "pie-timer-fill-animation" }), _jsx(PieTimerCircleInside, {}), _jsx("div", Object.assign({ className: "svg-overlay" }, { children: _jsx(Circle, {}) }))] })));
});
