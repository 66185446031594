import styled from 'styled-components';
export const Text = styled.p `
  font-size: ${(props) => props.theme.typography[props.$fontSize || 'H3']}px;
  font-weight: ${(props) => props.$fontWeight || 400};
  color: ${(props) => props.theme.colors[props.$color || 'GR1']};
  text-align: ${(props) => props.$textAlign || 'left'};
  line-height: ${(props) => props.theme.typography[props.$lineHeight || props.$fontSize || 'H3']}px;
  margin-bottom: ${(props) => props.$marginBottom || 0}px;
  width: ${(props) => props.$width}px;
`;
export const InlineText = Text.withComponent('span');
