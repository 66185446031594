var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import useSWR from 'swr';
import { usePriceTokens } from '../contexts';
export const useCoingeckoPrices = (addresses) => {
    const tokensMap = usePriceTokens();
    const fetcher = () => __awaiter(void 0, void 0, void 0, function* () {
        const tokensData = addresses
            .map((address) => {
            const { symbol, extensions } = tokensMap.get(address) || {
                symbol: '',
                extensions: { coingeckoId: '' },
            };
            return { symbol, address, coingeckoId: extensions.coingeckoId };
        })
            .filter((t) => !!t.coingeckoId);
        if (!tokensData.length) {
            return {
                pricesMapBySymbol: new Map(),
                pricesMapByAddress: new Map(),
            };
        }
        const prices = yield fetch(`https://api.coingecko.com/api/v3/simple/price?ids=${tokensData
            .map((t) => t.coingeckoId)
            .join(',')}&vs_currencies=usd`);
        const pricesResponse = yield prices.json();
        const pricesMapBySymbol = tokensData.reduce((acc, token) => {
            const { symbol, coingeckoId } = token;
            const { usd: price } = pricesResponse[coingeckoId] || { usd: 0 };
            return acc.set(symbol.toUpperCase(), price);
        }, new Map());
        const pricesMapByAddress = tokensData.reduce((acc, token) => {
            const { address, coingeckoId } = token;
            const { usd: price } = pricesResponse[coingeckoId] || { usd: 0 };
            return acc.set(address, price);
        }, new Map());
        return { pricesMapBySymbol, pricesMapByAddress };
    });
    const { data, mutate: refresh } = useSWR(`useCoingeckoPrices-${addresses.join(',')}`, fetcher, {
        refreshInterval: 60000,
    });
    if (!data) {
        return {
            pricesMapBySymbol: new Map(),
            pricesMapByAddress: new Map(),
            refresh,
        };
    }
    const { pricesMapBySymbol, pricesMapByAddress } = data;
    return { pricesMapBySymbol, pricesMapByAddress, refresh };
};
