import { polygonMumbai, /*polygon, goerli, mainnet, */ bscTestnet, bsc, arbitrum } from 'wagmi/chains';
import PolygonIcon from '../assets/polygon-logo.png';
import EthereumIcon from '../assets/ethereum-logo.png';
import BNBIcon from '../assets/bnb-logo.png';
import ArbitrumIcon from '../assets/arbitrum-logo.png';
import BerachainIcon from '../assets/berachain-logo.png';
// Supported networks
export const supportedNetworks = {
    polygonMumbai,
    // polygon,
    // goerli,
    // mainnet,
    bscTestnet,
    bsc,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    berachain: { network: 'berachain', name: 'Berachain', id: 11111 },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    arbitrum,
};
export const getNetworkNumber = (network) => {
    let networkNumber = 1;
    if (network === 'maticmum') {
        networkNumber = 0;
    }
    else if (network === 'bsc-testnet') {
        networkNumber = 3;
    }
    else if (network === 'bsc') {
        networkNumber = 2;
    }
    else if (network === 'berachain') {
        networkNumber = 3;
    }
    else if (network === 'arbitrum') {
        networkNumber = 4;
    }
    /* else if (network === 'goerli') {
      networkNumber = 2;
    } else if (network === 'homestead') {
      networkNumber = 3;
    }*/
    return networkNumber;
};
export const getNetworkIcon = (network) => {
    let networkLogo = null;
    if (network === 'maticmum' || network === 'matic') {
        networkLogo = PolygonIcon;
    }
    else if (network === 'homestead' || network === 'goerli') {
        networkLogo = EthereumIcon;
    }
    else if (network === 'bsc' || network === 'bsc-testnet') {
        networkLogo = BNBIcon;
    }
    else if (network === 'arbitrum') {
        networkLogo = ArbitrumIcon;
    }
    else if (network === 'berachain') {
        networkLogo = BerachainIcon;
    }
    return networkLogo;
};
