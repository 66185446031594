import styled, { keyframes } from 'styled-components';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import { ReactComponent as CircleLoader } from '../Loader/assets/circleLoader.svg';
import { FlexBlock } from '../Layout';
const Animation = keyframes `
  /* ----------------------------------------------
  * Generated by Animista on 2022-8-12 11:34:20
  * Licensed under FreeBSD License.
  * See http://animista.net/license for more info. 
  * w: http://animista.net, t: @cssanimista
  * ---------------------------------------------- */

        0% {
                transform: scale(1);
        }
        50% {
                transform: scale(1.1);
        }
        100% {
                transform: scale(1);
        }
`;
export const AnimatedLogo = styled(Logo) `
  flex: 1;
  animation: ${Animation} 1s ease-in-out infinite alternate forwards;
`;
export const Container = styled(FlexBlock) `
  flex: 1;
`;
export const SpinningAnimation = keyframes `
   from {
        transform: rotate(360deg);
   }
   to {
        transform: rotate(0deg);
   }
`;
export const AnimatedCircle = styled(CircleLoader) `
  animation: ${SpinningAnimation} linear 1.5s infinite both;
`;
