import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useConnect } from 'wagmi';
import { SpinningLoader } from '../Loader';
import { ReactComponent as MetaMaskIcon } from './assets/metamask.svg';
import { ReactComponent as WalletConnectIcon } from './assets/walletconnect.svg';
import { ReactComponent as CoinbaseIcon } from './assets/coinbaseWallet.svg';
import { ReactComponent as LedgerIcon } from './assets/ledgerIcon.svg';
import { StyledButton, StyledWalletContainer, WalletIconContainer } from './styles';
const walletIcons = {
    MetaMask: MetaMaskIcon,
    WalletConnect: WalletConnectIcon,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'Coinbase Wallet': CoinbaseIcon,
    Ledger: LedgerIcon,
};
export const WalletSelectModal = (props) => {
    const { showModal } = props;
    const { connectors, connect, isLoading, pendingConnector } = useConnect();
    const connectionHandler = (connector) => {
        if (connector) {
            try {
                connect({ connector });
                showModal(false);
            }
            catch (err) {
                console.log('Wallet connection failed: ', err);
            }
        }
    };
    return (_jsx(StyledWalletContainer, { children: connectors === null || connectors === void 0 ? void 0 : connectors.map((connector) => {
            const WalletIcons = walletIcons[connector.name];
            return (_jsxs(StyledButton, Object.assign({ disabled: !connector.ready, onClick: () => connectionHandler(connector) }, { children: [_jsx(WalletIconContainer, { children: WalletIcons && _jsx(WalletIcons, { width: "100%", height: "100%" }) }), connector.name, !connector.ready && ' (not installed)', isLoading && connector.id === (pendingConnector === null || pendingConnector === void 0 ? void 0 : pendingConnector.id) && (_jsx(SpinningLoader, {}))] }), connector.id));
        }) }));
};
