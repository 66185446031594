import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { callToast } from '../../contexts';
import { FlexBlock } from '../Layout';
import { Toast } from '../Toast/Toast';
import { FailedButtonsRow, TextButton } from './styles';
const DefaultFailedDescription = ({ onRetry }) => (_jsxs(FlexBlock, Object.assign({ "$alignItems": "center", "$justifyContent": "space-between" }, { children: [_jsx("span", { children: "Failed." }), _jsxs(FailedButtonsRow, { children: [_jsx(TextButton, Object.assign({ color: "C2" }, { children: "Cancel" })), onRetry && (_jsx(TextButton, Object.assign({ color: "B1", onClick: onRetry }, { children: "Try again" })))] })] })));
export const notify = ({ toastId = 'default-toast-id', type, title, description, progressOptions = { segments: 2, value: 2 }, onRetry, options, }) => {
    callToast(toastId, {
        render: () => (_jsx(Toast, { type: type, progressOptions: progressOptions, title: title, description: description ? description : !description ? '' : _jsx(DefaultFailedDescription, { onRetry: onRetry }) })),
        options: Object.assign({ autoClose: type === 'success' ? 5000 : false }, options),
    });
};
