import styled from 'styled-components';
export const Container = styled.div `
  display: flex;
  flex-direction: column;
  flex: 1;
  background: ${props => props.theme.colors.GR6};
  background-size: 100% 100%;
  font-family: ${props => props.theme.fonts.primary};
  color: ${props => props.theme.colors.C1};
`;
export const Content = styled.div `
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: ${props => props.contentAlign};
`;
