import styled from 'styled-components';
export const PlainInput = styled.input `
  background: none;
  border: none;
  font-family: ${(props) => props.theme.fonts.primary};
  outline: none;
  color: ${(props) => props.theme.colors.D7};

  &::placeholder {
    color: ${(props) => props.theme.colors.GR1};
  }
`;
