export const DOUBLE_REGEXP = /^\d+(\.?)\d{0,}$/;
export const INT_REGEXP = /^\d{0,}$/;
export const validateRegexp = (regexp, v) => {
    const isValid = !!v.match(regexp);
    if (!isValid) {
        return false;
    }
    return true;
};
export const validateDecimal = (v) => validateRegexp(DOUBLE_REGEXP, v);
export const validateNatural = (v) => validateRegexp(INT_REGEXP, v);
export const formattedStringToNumberString = (s) => {
    const newString = s ? s.replaceAll(',', '').replaceAll(' ', '') : s;
    return newString;
};
