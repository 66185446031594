import styled from 'styled-components';
import { Button } from '../Button';
export const CloseButton = styled(Button) `
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  cursor: pointer;
  font-weight: normal;
  background-color: ${(props) => props.theme.colors.GR2};
  border-radius: ${(props) => props.theme.borderRadius.xs};
  color: ${(props) => props.theme.colors.D7};
`;
