import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { FlexBlock, InlineText, Modal, } from '../../components';
import { EscButton } from '../EscButton';
import { getNetworkIcon, getNetworkNumber } from '../../utils/networks';
import { DepositAmountsContainer, EscButtonContainer, ModalSectionTitle, ModalSectionTitleContainer, ModalSingleBlockContent, ModalTitleContainer, ModalTitlePairName, ContentContainer, NetworkRow, } from './styles';
export const NetworkSwitchModal = ({ supportedNetworks, onNetworkClick, isOpen, close, }) => {
    return (_jsx(Modal, Object.assign({ open: isOpen, onClose: close }, { children: _jsxs(ContentContainer, Object.assign({ "$direction": "column" }, { children: [_jsxs(ModalTitleContainer, { children: [_jsx(FlexBlock, Object.assign({ "$alignItems": "center" }, { children: _jsx(FlexBlock, Object.assign({ "$direction": "column" }, { children: _jsx(ModalTitlePairName, { children: "Switch Network" }) })) })), _jsx(FlexBlock, { children: _jsx(EscButtonContainer, { children: _jsx(EscButton, { size: 48, onClick: close }) }) })] }), _jsx(ModalSingleBlockContent, { children: _jsxs(DepositAmountsContainer, { children: [_jsx(ModalSectionTitleContainer, { children: _jsx(ModalSectionTitle, { children: "Available Networks" }) }), _jsx("div", { children: Object.entries(supportedNetworks).map(([key, networkObject]) => {
                                    const networkLogo = getNetworkIcon(networkObject === null || networkObject === void 0 ? void 0 : networkObject.network);
                                    const networkNumber = getNetworkNumber(networkObject === null || networkObject === void 0 ? void 0 : networkObject.network);
                                    const { name, id, network } = networkObject;
                                    const disabled = network === 'bsc-testnet' || network === 'bsc' || network === 'berachain' || network === 'arbitrum';
                                    return (_jsxs(NetworkRow, Object.assign({ onClick: () => !disabled && onNetworkClick(Number(id)), "$disabled": disabled }, { children: [networkLogo && (_jsx("img", { src: networkLogo, alt: "Network Logo" })), _jsxs(InlineText, Object.assign({ "$fontSize": "H4", "$fontWeight": 600, "$color": "GR2" }, { children: [network === 'arbitrum' && networkNumber === 3 ? 'Berachain' : name, " ", disabled && '(soon)'] }))] }), key));
                                }) })] }) })] })) })));
};
