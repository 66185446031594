import { useEffect } from 'react';
const useCloseEsc = ({ close }) => {
    useEffect(() => {
        const listener = (e) => {
            if (e.code === 'Escape') {
                close();
            }
        };
        document.addEventListener('keydown', listener);
        return () => {
            document.removeEventListener('keydown', listener);
        };
    }, [close]);
};
export { useCloseEsc };
