import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
// import styled from 'styled-components';
import { InlineText } from '../Typography';
import { FlexBlock } from '../Layout';
import { PrivacyPolicy } from '../../modals';
import { Container, SocialLinks } from './styles';
import { ReactComponent as FooterLogo } from './assets/footer-logo.svg';
import { ReactComponent as Twitter } from './assets/twitter.svg';
import { ReactComponent as Telegram } from './assets/telegram.svg';
// const OpenPrivacyPopupButton = styled(Text)`
//   font-size: ${(props) => props.theme.typography.H5}px;
//   cursor: pointer;
//   color: ${(props) => props.theme.colors.C0};
//   margin-left: ${(props) => props.theme.spacings.md}px;
// `;
export const Footer = () => {
    const [isPrivacyPolicyModalOpen, setIsPrivacyPolicyModalOpen] = useState(false);
    return (_jsx(FlexBlock, Object.assign({ "$justifyContent": 'center' }, { children: _jsxs(Container, { children: [_jsx(FlexBlock, Object.assign({ "$alignItems": 'center' }, { children: _jsx(FooterLogo, {}) })), _jsx(InlineText, Object.assign({ "$fontSize": "H4", "$color": "C0" }, { children: "RAMM - An AMM that greatly reduces impermanent loss." })), _jsxs(SocialLinks, { children: [_jsx("a", Object.assign({ href: "https://twitter.com/aldrin_labs", target: "_blank", rel: "noopener noreferrer" }, { children: _jsx(Twitter, {}) })), _jsx("a", Object.assign({ href: "https://t.me/rincels", target: "_blank", rel: "noopener noreferrer" }, { children: _jsx(Telegram, {}) }))] }), _jsx(PrivacyPolicy, { isOpen: isPrivacyPolicyModalOpen, close: () => setIsPrivacyPolicyModalOpen(false) })] }) })));
};
