import { jsx as _jsx } from "react/jsx-runtime";
import './globals';
import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga4';
import { Decimal } from 'decimal.js';
import App from './App';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
if (process.env.NODE_ENV !== 'development') {
    ReactGA.initialize('G-YT8281FGE0');
}
Decimal.config({ precision: 36, rounding: 8, toExpNeg: -40, toExpPos: 40 });
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(_jsx(App, {}));
