var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-unused-vars */
import { setContracts } from '@ramm/ramm-sdk/interface/interface';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAccount, useConnect, useDisconnect, useEnsName, useBalance, useProvider, useNetwork, } from 'wagmi';
import { getNetworkNumber } from '../../utils/networks';
const defaultState = {
    address: undefined,
    isConnected: undefined,
    balanceData: undefined,
    ensName: undefined,
    connect: () => {
    },
    disconnect: () => {
    },
    error: undefined,
    balanceLoading: undefined,
    pendingConnector: undefined,
    network: undefined,
    allPools: [],
};
export const Web3Context = createContext(defaultState);
export const Web3Provider = ({ children, }) => {
    const [state, setState] = useState(defaultState);
    const { address, isConnected } = useAccount();
    const provider = useProvider();
    const { chain } = useNetwork();
    const { data, isLoading: balanceLoading, } = useBalance({
        address: address,
    });
    const { data: ensName } = useEnsName({ address });
    const { connect } = useConnect();
    const { disconnect } = useDisconnect();
    useEffect(() => {
        setState({
            isConnected,
            address,
            balanceData: data,
            connect,
            disconnect,
            ensName: ensName,
            balanceLoading,
            network: chain,
        });
    }, [isConnected, address, data, connect, disconnect, ensName, balanceLoading, chain]);
    useEffect(() => {
        function fetchLpTokens() {
            return __awaiter(this, void 0, void 0, function* () {
                try {
                    const network = chain === null || chain === void 0 ? void 0 : chain.network;
                    if (!network)
                        return;
                    const networkNumber = getNetworkNumber(network);
                    const allPools = setContracts(provider, networkNumber);
                    setState(prevState => {
                        return Object.assign(Object.assign({}, prevState), { allPools });
                    });
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                }
                catch (err) {
                    console.log('Fetch error', err);
                }
            });
        }
        fetchLpTokens();
    }, [address, data, connect, disconnect, ensName, balanceLoading, provider, chain, isConnected]);
    return (_jsx(Web3Context.Provider, Object.assign({ value: Object.assign({}, state) }, { children: children })));
};
export const useWeb3 = () => {
    const ctx = useContext(Web3Context);
    if (!ctx) {
        throw new Error('Error');
    }
    return ctx;
};
