var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useState, useEffect } from 'react';
import { useNetwork } from 'wagmi';
import Testtokens from '../../config/tokenList.json';
const Context = createContext(undefined);
export const TokensProvider = ({ children }) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [tokenList, setTokenList] = useState(new Map());
    const { chain } = useNetwork();
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const tokenMap = new Map();
            if (!chain) {
                Testtokens.forEach((token) => {
                    tokenMap.set(token.address, token);
                });
            }
            else if (chain.network == 'polygon' || chain.network === 'matic') {
                Testtokens.forEach((token) => {
                    if (token.chainId == 137) {
                        tokenMap.set(token.address, token);
                    }
                });
            }
            else if (chain.network == 'polygonMumbai' || chain.network === 'maticmum') {
                Testtokens.forEach((token) => {
                    if (token.chainId == 80001) {
                        tokenMap.set(token.address, token);
                    }
                });
            }
            else if (chain.network == 'bsc-testnet') {
                Testtokens.forEach((token) => {
                    if (token.chainId == 97) {
                        tokenMap.set(token.address, token);
                    }
                });
            }
            else {
                Testtokens.forEach((token) => {
                    tokenMap.set(token.address, token);
                });
            }
            setTokenList(tokenMap);
        }))();
    }, [chain]);
    return _jsx(Context.Provider, Object.assign({ value: { tokens: tokenList } }, { children: children }));
};
export const useTokens = () => {
    const ctx = useContext(Context);
    if (!ctx) {
        throw new Error('useTokens must be used within a TokensProvider');
    }
    return ctx.tokens;
};
export const useTokenInfo = (tokenAddress) => {
    const tokens = useTokens();
    return tokens.get(tokenAddress);
};
