import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FlexBlock } from '../../Layout';
import { Message, SubButton } from './styles';
export const FailedTransactionNotification = (props) => {
    const { message = 'Transaction Failed', onRetry, txId } = props;
    return (_jsxs(FlexBlock, Object.assign({ "$direction": 'column', "$justifyContent": 'flex-end' }, { children: [_jsx(Message, Object.assign({ needMargin: !!(onRetry || txId), "$textAlign": 'right', "$fontWeight": 600 }, { children: message })), !!(onRetry || txId) &&
                _jsxs(FlexBlock, Object.assign({ "$direction": 'row', "$justifyContent": 'flex-end' }, { children: [txId &&
                            _jsx(SubButton, Object.assign({ as: "a", "$fontSize": "H5", "$color": "B1", "$fontWeight": 600, href: `https://explorer.near.org/transactions/${txId}` }, { children: "Explorer" })), onRetry &&
                            _jsx(SubButton, Object.assign({ "$fontSize": "H5", "$color": "B1", "$fontWeight": 600, onClick: onRetry }, { children: "Try again" }))] }))] })));
};
