import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import { FlexBlock, FlexFull, InlineText, Text } from '../../components';
const ModalTitleContainer = styled(FlexFull) `
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.GR3};
  padding: ${(props) => props.theme.spacings.xmd}px ${(props) => props.theme.spacings.lg}px;
`;
const ModalTitlePairName = styled((props) => (_jsx(Text, Object.assign({ "$color": "GR2", "$fontSize": "H2", "$fontWeight": 700, "$lineHeight": "H0" }, props)))) ``;
const EscButtonContainer = styled.div `
  margin-left: ${(props) => props.theme.spacings.md}px;

  button {
    color: ${(props) => props.theme.colors.D7};
    border-radius: ${(props) => props.theme.borderRadius.xs}px;
  }
`;
const ModalSectionTitleContainer = styled(FlexFull) `
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${(props) => props.theme.spacings.sm}px;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    flex-direction: column;
    align-items: flex-start;
    gap: ${(props) => props.theme.spacings.xs}px;
  }
`;
const ModalSectionTitle = styled((props) => (_jsx(Text, Object.assign({ "$color": "GR2", "$fontSize": "H5", "$fontWeight": 700, "$lineHeight": "H0" }, props)))) ``;
const ModalSingleBlockContent = styled(FlexFull) `
  flex-direction: column;

  padding: ${(props) => props.theme.spacings.md}px ${(props) => props.theme.spacings.lg}px ${(props) => props.theme.spacings.lg}px ${(props) => props.theme.spacings.lg}px;
`;
const DepositAmountsContainer = styled(FlexBlock) `
  width: 100%;
  flex-direction: column;
  margin-top: ${(props) => props.theme.spacings.sm}px;
`;
const ContentContainer = styled(FlexFull) `
  width: 356px;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}px) {
    width: 100%;
  }
`;
export const NetworkRow = styled(FlexBlock) `
  padding: ${(props) => props.theme.spacings.md}px 0;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: center;

  ${(props) => props.$disabled &&
    `
          cursor: not-allowed;
          opacity: 0.8;
  `}

  &:hover {
    opacity: 0.7;
  }

  ${InlineText} {
    padding-left: ${(props) => props.theme.spacings.xs}px;
  }

  img {
    height: 24px;
    width: 25px;
    margin-right: ${(props) => props.theme.spacings.xs}px;
  }
`;
export { ModalTitleContainer, ModalTitlePairName, EscButtonContainer, ModalSectionTitleContainer, ModalSectionTitle, ModalSingleBlockContent, DepositAmountsContainer, ContentContainer, };
