import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { useWeb3 } from '../../contexts';
import { Modal } from '../Modal';
import { WalletSelectModal } from '../WalletSelectModal/WalletSelectModal';
import { EscButton } from '../EscButton';
import { Text } from '../Typography';
import { ModalContainers, StyledModalTitleContainer, WithConnectedWalletButton } from './styles';
export const WithConnectedWallet = (props) => {
    const { width, size, children, margin } = props;
    const [modalOpen, setModalOpen] = useState(false);
    const { isConnected } = useWeb3();
    if (!isConnected) {
        return (_jsxs(_Fragment, { children: [_jsx(WithConnectedWalletButton, Object.assign({ "$margin": margin, "$width": width, "$size": size, onClick: () => setModalOpen(true) }, { children: "Connect Wallet" })), modalOpen && (_jsx(Modal, Object.assign({ open: modalOpen, onClose: () => setModalOpen(false) }, { children: _jsxs(ModalContainers, { children: [_jsxs(StyledModalTitleContainer, { children: [_jsx(Text, Object.assign({ "$fontWeight": 700, "$fontSize": "H2", "$color": 'GR2' }, { children: "Select your Wallet" })), _jsx(EscButton, { onClick: () => setModalOpen(false) })] }), _jsx(WalletSelectModal, { showModal: setModalOpen })] }) })))] }));
    }
    return _jsx(_Fragment, { children: children });
};
