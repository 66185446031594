import styled from 'styled-components';
export const StyledWalletContainer = styled.div `
  width: 350px;
  display: flex;
  flex-direction: column;
  margin: ${(props) => props.theme.spacings.sm}px 0;
`;
export const StyledButton = styled.button `
  cursor: pointer;
  padding: 1.8em 1em;
  border: none;
  border-radius: ${(props) => props.theme.borderRadius.xs}px;
  background: ${(props) => props.theme.colors.GR3};
  color: ${(props) => props.theme.colors.GR2};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1.8em;
  border-radius: ${(props) => props.theme.borderRadius.sm}px;
  font-family: ${(props) => props.theme.fonts.primary};
  font-weight: 500;
  font-size: ${(props) => props.theme.typography.H2};
  margin: ${(props) => props.theme.spacings.sm}px 0;
  box-shadow: 8px 8px 4px rgba(0, 0, 0, 0.5);

  &:hover {
    background: ${(props) => props.theme.colors.GR4};
  }
`;
export const WalletIconContainer = styled.div `
  width: 45px;
  height: 45px;
`;
