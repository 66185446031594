import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { formatNumberToUSFormat, getNumberOfDecimalsFromNumber, getNumberOfIntegersFromNumber, numberWithOneDotRegexp, } from '../../utils';
import { PlainInput } from './styles';
import { validateDecimal, validateNatural } from './utils';
import { ReactComponent as SearchIcon } from './assets/search.svg';
export const INPUT_FORMATTERS = {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    NOP: (e, prevValue) => e,
    DECIMAL2: (v, prevValue) => {
        let value = v ? `${v}`.replaceAll(',', '').replaceAll(' ', '') : `${v}`;
        if (value === '') {
            return value;
        }
        // controls behavior if the value is zero
        if (value[0] === '0' && /^\d+$/.test(value[1])) {
            value = `${value.slice(1)}`; // if zero, removes the zero and add the next number
        }
        if (validateDecimal(value) &&
            numberWithOneDotRegexp.test(value) &&
            getNumberOfIntegersFromNumber(value) <= 8 &&
            getNumberOfDecimalsFromNumber(value) <= 2 // Limit decimals to 2
        ) {
            return formatNumberToUSFormat(value);
        }
        return `${prevValue !== null && prevValue !== void 0 ? prevValue : 0}`;
    },
    DECIMAL6: (v, prevValue) => {
        let value = v ? `${v}`.replaceAll(',', '').replaceAll(' ', '') : `${v}`;
        if (value === '') {
            return value;
        }
        // controls behaviour if the value is zero
        if (value[0] === '0' && /^\d+$/.test(value[1])) {
            value = `${value.slice(1)}`; // if zero, removes the zero and add the next number
        }
        if (validateDecimal(value) &&
            numberWithOneDotRegexp.test(value) &&
            getNumberOfIntegersFromNumber(value) <= 8 &&
            getNumberOfDecimalsFromNumber(value) <= 6) {
            return formatNumberToUSFormat(value);
        }
        return `${prevValue !== null && prevValue !== void 0 ? prevValue : 0}`;
    },
    DECIMAL18: (v, prevValue) => {
        let value = v ? `${v}`.replaceAll(',', '').replaceAll(' ', '') : `${v}`;
        if (value === '') {
            return value;
        }
        // controls behaviour if the value is zero
        if (value[0] === '0' && /^\d+$/.test(value[1])) {
            value = `${value.slice(1)}`; // if zero, removes the zero and add the next number
        }
        if (validateDecimal(value) &&
            numberWithOneDotRegexp.test(value) &&
            getNumberOfIntegersFromNumber(value) <= 8 &&
            getNumberOfDecimalsFromNumber(value) <= 18) {
            return formatNumberToUSFormat(value);
        }
        return `${prevValue !== null && prevValue !== void 0 ? prevValue : 0}`;
    },
    NATURAL: (v, prevValue) => {
        if (validateNatural(v) || v === '') {
            return v;
        }
        return prevValue;
    },
};
export const Input = React.forwardRef((props, ref) => {
    const { formatter = INPUT_FORMATTERS.NOP, size = 18, onChange, onFocus, className, placeholder, autoFocus = false, onBlur, disabled = false, } = props;
    const [cursor, setCursor] = useState(null);
    const onChangeHandler = (e) => {
        const formatted = formatter(e.target.value, props.value);
        if (formatted !== props.value) {
            onChange(formatted);
            const diffInLength = formatted.length - e.target.value.length;
            if (e.target.selectionStart !== null) {
                setCursor(e.target.selectionStart + diffInLength);
            }
        }
    };
    useEffect(() => {
        if (ref) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const input = ref.current;
            if (input)
                input.setSelectionRange(cursor, cursor);
        }
    }, [ref, cursor, props.value]);
    return (_jsx(PlainInput, { autoFocus: autoFocus, value: props.value, ref: ref, size: size, disabled: disabled, onFocus: onFocus, onBlur: onBlur, onChange: onChangeHandler, className: className, placeholder: placeholder }));
});
const SearchInputField = styled(Input) `
  flex: 1;
  height: 32px;
  line-height: 20px;
  margin-left: 10px;
  color: ${(props) => props.theme.colors.C0};
`;
const StyledSearchIcon = styled(SearchIcon) `
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  margin-left: ${(props) => props.theme.spacings.md}px;
`;
export const SearchInput = React.forwardRef((props, ref) => {
    const { placeholder, value, onChange, onFocus, autoFocus, iconSize = 12 } = props;
    return (_jsxs(_Fragment, { children: [_jsx(StyledSearchIcon, { size: iconSize }), _jsx(SearchInputField, { autoFocus: autoFocus, value: value, onChange: onChange, onFocus: onFocus, placeholder: placeholder, ref: ref })] }));
});
