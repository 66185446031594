import styled from 'styled-components';
export const Placeholder = styled.div `
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  line-height: ${(props) => props.size}px;
  display: inline-block;
  background: ${(props) => props.theme.colors.C4};
  border-radius: 50%;
  text-align: center;
`;
export const Img = styled.img `
  background: ${(props) => props.theme.colors[props.$bg || 'C1']};
  border-radius: 50%;
`;
export const PairContainer = styled.div `
  display: flex;
  align-items: center;
  
  img {
    margin-right: ${(props) => props.size / 2}px;

    &:first-child {
      margin-right: -${(props) => props.size / 2}px;
      position: relative;
    }
  }
`;
