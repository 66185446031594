import styled from 'styled-components';
export const PieTimerContainer = styled.div `
  position: relative;
  background: ${(props) => props.theme.colors.GR6};
  border-radius: 50%;
  width: ${(props) => props.$size}px;
  height: ${(props) => props.$size}px;

  cursor: pointer;

  &:active {
    --percentage: 100%;
  }

  @property --percentage {
    initial-value: 100%;
    inherits: false;
    syntax: "<percentage>";
  }

  @keyframes timer {
    to {
      --percentage: 0%;
    }
  }

  .pie-timer-fill-animation {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;

    background: conic-gradient(
      rgba(0, 0, 0, 0) var(--percentage),
      ${(props) => props.theme.colors.GR6} 0
    );
    z-index: 3;
  }

  ${(props) => !props.$disabled &&
    `
      &:not(:active) {
        .pie-timer-fill-animation {
          animation: timer ${props.$duration}s infinite linear;
        }
      }
  `}

  .svg-overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    width: ${(props) => props.$size}px;
    height: ${(props) => props.$size}px;
    z-index: 2;

    svg {
      width: 94%;
      height: 94%;
      margin: 3%;

      path {
        stroke: ${({ theme }) => theme.colors.GR2};
      }
    }
  }
`;
export const PieTimerCircleInside = styled.div `
  position: absolute;
  left: calc(50%);
  top: calc(50%);
  transform: translate(-50%, -50%);
  border-radius: 50%;
  width: 0.5em;
  height: 0.5em;
  border: 1px solid ${({ theme }) => theme.colors.GR1};
  background: transparent;
  z-index: 3;
`;
