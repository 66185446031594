import styled, { keyframes } from 'styled-components';
import { rgba } from 'polished';
import { Card } from '../Card';
const kf = keyframes `
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
export const ModalContainer = styled.div `
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 101;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  animation: 0.1s ${kf} ease-out;
  background: ${rgba('#000', 0.2)};
  backdrop-filter: blur(10px);
`;
export const ModalBody = styled(Card) `
  height: auto;
  max-width: 80em;
  max-height: 95vh;
  overflow: auto;
  width: ${(props) => props.$width || 'auto'};

  @media (max-width: ${(props) => props.$wholeScreenResolution || props.theme.breakpoints.sm}px) {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
  }
`;
export const ModalContent = styled.div `
  padding: 0;
`;
export const ModalTitle = styled.h3 `
  margin: 0;
  color: ${(props) => props.theme.colors.GR2};
  padding-right: ${(props) => props.theme.spacings.md}px;
`;
export const ModalTitleContainer = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: ${(props) => props.theme.spacings.lg}px
    ${(props) => props.theme.spacings.md}px
    ${(props) => props.theme.spacings.md}px
    ${(props) => props.theme.spacings.md}px;
`;
