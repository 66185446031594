import styled from 'styled-components';
import { ModalTitleContainer } from '../Modal/styles';
import { Button } from '../Button';
export const ModalContainers = styled.div `
  display: flex;
  flex-direction: column;
  background-color: transparent;
  padding: ${(props) => props.theme.spacings.md}px ${(props) => props.theme.spacings.lg}px;
`;
export const StyledModalTitleContainer = styled(ModalTitleContainer) `
  margin-left: 0;
  margin-right: 0;
`;
export const WithConnectedWalletButton = styled(Button) `
  background-color: ${(props) => props.theme.colors.GR2};
`;
