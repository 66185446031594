import { jsx as _jsx } from "react/jsx-runtime";
import { WagmiConfig, createClient, configureChains } from 'wagmi';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { polygonMumbai, /*polygon, goerli, mainnet, */ bscTestnet, bsc } from 'wagmi/chains';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet';
import { InjectedConnector } from 'wagmi/connectors/injected';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import { LedgerConnector } from 'wagmi/connectors/ledger';
const apiKey = 'tTKd8DtfznX-6xa3GtMbAJ2dfeTHtAkq';
const { chains, provider, webSocketProvider } = configureChains([polygonMumbai, /*polygon, goerli, mainnet, */ bscTestnet, bsc], [alchemyProvider({ apiKey }), publicProvider()]);
// Set up client
const client = createClient({
    autoConnect: true,
    connectors: [
        new MetaMaskConnector({ chains }),
        new CoinbaseWalletConnector({
            chains,
            options: {
                appName: 'wagmi',
            },
        }),
        new WalletConnectConnector({
            chains,
            options: {
                qrcode: true,
            },
        }),
        new LedgerConnector({
            chains,
        }),
        new InjectedConnector({
            chains,
            options: {
                name: 'Injected',
                shimDisconnect: true,
            },
        }),
    ],
    provider,
    webSocketProvider,
});
export const WagmiProvider = ({ children, }) => _jsx(WagmiConfig, Object.assign({ client: client }, { children: children }));
