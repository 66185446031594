import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { Portal } from 'react-portal';
import { useCloseEsc } from '../../hooks/useCloseEsc';
import { EscButton } from '../EscButton';
import { ModalBody, ModalContainer, ModalContent, ModalTitle, ModalTitleContainer, } from './styles';
export const ModalTitleBlock = (props) => {
    const { title, onClose } = props;
    return (_jsxs(ModalTitleContainer, { children: [_jsx(ModalTitle, { children: title }), _jsx(EscButton, { onClick: onClose })] }));
};
export const Modal = (props) => {
    const { open, onClose, children, title, width, $wholeScreenResolution } = props;
    useCloseEsc({ close: onClose });
    useEffect(() => {
        if (open) {
            document.body.classList.add('modal-open');
        }
        return () => {
            document.body.classList.remove('modal-open');
        };
    }, [open, onClose]);
    if (!open) {
        return null;
    }
    return (_jsx(Portal, { children: _jsx(ModalContainer, Object.assign({ onClick: onClose }, { children: _jsx(ModalBody, Object.assign({ onClick: (e) => e.stopPropagation(), "$width": width, "$wholeScreenResolution": $wholeScreenResolution }, { children: _jsx(ModalContent, { children: _jsxs(_Fragment, { children: [title && _jsx(ModalTitleBlock, { title: title, onClose: onClose }), children] }) }) })) })) }));
};
