import styled from 'styled-components';
import { FlexBlock } from '../../Layout';
import { TokenIcon } from '../TokenIcon';
const Container = styled(FlexBlock) `
  height: 40px;
  background: ${(props) => props.theme.colors.C5};
  border-radius: ${(props) => props.theme.borderRadius.xs}px;
  padding: 0 ${(props) => props.theme.spacings.sm}px;
  cursor: pointer;
  transition: all 0.5s;
  font-size: ${(props) => props.theme.typography.H4}px;
  font-weight: 600;
  color: ${(props) => props.theme.colors.C1};
`;
const StyledTokenIcon = styled(TokenIcon) `
  margin-right: ${(props) => props.theme.spacings.sm}px;
`;
export { Container, StyledTokenIcon };
