import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Suspense } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, Navigate, Route, Routes, useLocation, } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { PageLayout } from './components';
import { TokensProvider, TransactionManager, Web3Provider, PriceProvider, } from './contexts';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import { GlobalAppStyle } from './style';
import { RammThemeProvider } from './theme';
import { WagmiProvider } from './utils/WagmiProvider';
const SwapPage = React.lazy(() => import('./pages/SwapPage/SwapPage'));
const Liquidity = React.lazy(() => import('./pages/Liquidity/Liquidity'));
const Pages = () => {
    const location = useLocation();
    const contentAlign = location.pathname === '/' ? 'center' : 'flex-start';
    return (_jsx(PageLayout, Object.assign({ contentAlign: contentAlign }, { children: _jsxs(Routes, { children: [_jsx(Route, { index: true, element: _jsx(React.Suspense, Object.assign({ fallback: _jsx(_Fragment, { children: "..." }) }, { children: _jsx(SwapPage, {}) })) }), _jsx(Route, { path: "liquidity", element: _jsx(React.Suspense, Object.assign({ fallback: _jsx(_Fragment, { children: "..." }) }, { children: _jsx(Liquidity, {}) })) }), _jsx(Route, { path: "/liquidity", element: _jsx(Liquidity, {}) }), _jsx(Route, { path: "*", element: _jsx(Navigate, { to: "/", replace: true }) })] }) })));
};
function App() {
    return (_jsx(BrowserRouter, { children: _jsx(HelmetProvider, { children: _jsx(WagmiProvider, { children: _jsx(Web3Provider, { children: _jsx(RammThemeProvider, { children: _jsx(TransactionManager, { children: _jsx(TokensProvider, { children: _jsx(PriceProvider, { children: _jsxs(Suspense, { children: [_jsx(GlobalAppStyle, {}), _jsxs(Helmet, { children: [_jsx("meta", { charSet: "utf-8" }), _jsx("title", { children: "RAMM | by Aldrin." })] }), _jsx(Pages, {}), _jsx(ToastContainer, { position: "bottom-left", icon: false, hideProgressBar: true, pauseOnFocusLoss: false, closeButton: false })] }) }) }) }) }) }) }) }) }));
}
export default App;
