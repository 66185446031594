var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { createContext, useCallback, useRef, useContext, } from 'react';
import { toast } from 'react-toastify';
import { FailedTransactionNotification, InfoIcon } from '../../components';
import { useMatchMutate } from '../../hooks';
const Context = createContext({
    signAndSendTransactions: () => __awaiter(void 0, void 0, void 0, function* () {
    }),
});
export const callToast = (toastId, { render, options = {}, }) => {
    if (toast.isActive(toastId)) {
        toast.update(toastId, Object.assign({ render, position: toast.POSITION.BOTTOM_LEFT, autoClose: 3000 }, options));
    }
    else {
        toast(render, Object.assign({ toastId, position: toast.POSITION.BOTTOM_LEFT, hideProgressBar: true, autoClose: 3000 }, options));
    }
};
export const TransactionManager = (props) => {
    const updateCachesFn = useMatchMutate();
    const notification = useRef(null);
    const signTransactions = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (transactions, params) => __awaiter(void 0, void 0, void 0, function* () {
        const { processingMessage = 'Signing transactions...', updateCaches = [], onRetry = null, } = params || {};
        if (notification.current && toast.isActive(notification.current)) {
            toast.error('Another notification is already in progress');
            throw new Error('Another notification is already in progress');
        }
        notification.current = toast(processingMessage, {
            type: 'info',
            autoClose: false,
            icon: () => _jsx(InfoIcon, { step: 1, type: "pending" }),
            closeOnClick: false,
        });
        try {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }
        catch (e) {
            // If tx failed or user cancelled the transaction
            console.log('Error sign:', e);
            if (notification.current) {
                const errorText = e.toString().toLowerCase();
                const isUserReject = errorText.includes('user reject');
                console.log('errorText:', errorText);
                if (isUserReject) {
                    toast.update(notification.current, {
                        render: (_jsx(FailedTransactionNotification, { message: "Transaction cancelled", onRetry: () => signTransactions(transactions, params) })),
                        icon: () => _jsx(InfoIcon, { step: 2, type: "error" }),
                        type: 'error',
                        autoClose: 5000,
                    });
                }
                else {
                    toast.update(notification.current, {
                        render: (_jsx(FailedTransactionNotification, { message: "Transaction failed", onRetry: onRetry !== undefined
                                ? onRetry
                                : () => {
                                    signTransactions(transactions, params);
                                } })),
                        icon: () => _jsx(InfoIcon, { step: 2, type: "error" }),
                        type: 'error',
                        autoClose: false,
                    });
                }
            }
            notification.current = null;
            if (updateCaches) {
                updateCachesFn(updateCaches);
            }
            console.warn('Unexpected error:', e);
        }
    }), [updateCachesFn]);
    return (_jsx(Context.Provider, Object.assign({ value: { signAndSendTransactions: signTransactions } }, { children: props.children })));
};
export const useTransactionManager = () => useContext(Context);
