import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { IconContainer, StatusLeftIcon, StatusRightIcon, IconContainerInner, StatusIcon, StatusSideWrap, } from './styles';
import { ReactComponent as Error } from './assets/error.svg';
import { ReactComponent as Pending } from './assets/pending.svg';
import { ReactComponent as Success } from './assets/success.svg';
const resolveIconComponent = (status) => {
    switch (status) {
        case 'success':
            return Success;
        case 'error':
            return Error;
        default:
            return Pending;
    }
};
export const InfoIcon = (props) => {
    const { step, type } = props;
    const Icon = resolveIconComponent(type);
    return (_jsx(IconContainer, Object.assign({ type: type }, { children: _jsxs(IconContainerInner, { children: [_jsx(StatusSideWrap, { children: step > 1 && _jsx(StatusLeftIcon, {}) }), _jsx(StatusIcon, Object.assign({ id: type === 'pending' ? 'progress-spin' : '' }, { children: _jsx(Icon, {}) })), _jsx(StatusSideWrap, { children: _jsx(StatusRightIcon, {}) })] }) })));
};
