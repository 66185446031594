var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { useWeb3 } from '../../contexts';
import { trimAccountId } from '../../utils/wallet';
import { NavBar } from '../NavBar';
import { WithConnectedWallet } from '../WithConnectedWallet';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import { FlexBlock } from '../Layout';
import { SpinningLoader } from '../Loader';
import { NetworkSwitchModal } from '../../components';
import { getNetworkIcon, supportedNetworks } from '../../utils/networks';
import { ConnectedWalletContainer, Container, DisconnectButton, MenuContainer, SelectNetworkButton, WalletCard, WalletContainer, WalletText, } from './styles';
export const Header = () => {
    const { address, isConnected, ensName, disconnect, balanceData, balanceLoading, network } = useWeb3();
    const [networkSwitchModalOpen, setNetworkSwitchModalOpen] = useState(false);
    const switchNetwork = (chainId) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        try {
            yield ((_a = window.ethereum) === null || _a === void 0 ? void 0 : _a.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: `0x${chainId.toString(16)}` }],
            }));
            setNetworkSwitchModalOpen(false);
        }
        catch (error) {
            console.error('Error switching network:', error);
        }
    });
    const networkLogo = getNetworkIcon(network === null || network === void 0 ? void 0 : network.network);
    return (_jsx(FlexBlock, Object.assign({ "$justifyContent": 'center' }, { children: _jsxs(Container, { children: [_jsx("a", Object.assign({ href: "/" }, { children: _jsx(Logo, {}) })), _jsx(MenuContainer, { children: _jsx(NavBar, {}) }), _jsxs(ConnectedWalletContainer, { children: [isConnected && (_jsxs(_Fragment, { children: [_jsx(SelectNetworkButton, Object.assign({ onClick: () => setNetworkSwitchModalOpen(true) }, { children: network === null || network === void 0 ? void 0 : network.name })), _jsx(NetworkSwitchModal, { isOpen: networkSwitchModalOpen, close: () => setNetworkSwitchModalOpen(false), supportedNetworks: supportedNetworks, onNetworkClick: switchNetwork })] })), _jsx(WalletContainer, { children: _jsx(WithConnectedWallet, { children: isConnected ? (_jsxs(WalletCard, { children: [networkLogo && (_jsx("img", { src: networkLogo, alt: "Network Logo" })), _jsxs("div", { children: [_jsx("div", { children: _jsxs(WalletText, Object.assign({ "$fontWeight": 600, "$fontSize": "H5", "$color": "GR2" }, { children: [balanceLoading && _jsx(SpinningLoader, {}), balanceData && (balanceData === null || balanceData === void 0 ? void 0 : balanceData.formatted.slice(0, 4)), " ", balanceData === null || balanceData === void 0 ? void 0 : balanceData.symbol] })) }), _jsx("div", { children: _jsx(WalletText, Object.assign({ "$fontWeight": 400, "$fontSize": "H5", "$color": "GR1" }, { children: ensName ? `(${address})` : address && trimAccountId(address) })) })] }), _jsx(DisconnectButton, Object.assign({ onClick: disconnect }, { children: "Disconnect" }))] })) : null }) })] })] }) })));
};
