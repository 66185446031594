var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, useContext, useState, useEffect } from 'react';
import tokensPrice from '../../config/priceList.json';
//For token prices test
const PriceContext = createContext(undefined);
export const PriceProvider = ({ children }) => {
    const [tokenList, setTokenList] = useState(new Map());
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            const tokenMap = new Map();
            tokensPrice.forEach((token) => {
                tokenMap.set(token.address, token);
            });
            setTokenList(tokenMap);
        }))();
    }, []);
    return _jsx(PriceContext.Provider, Object.assign({ value: { tokens: tokenList } }, { children: children }));
};
export const usePriceTokens = () => {
    const ctx = useContext(PriceContext);
    if (!ctx) {
        throw new Error('useOtherTokens must be used within an OtherTokensProvider');
    }
    return ctx.tokens;
};
export const usePriceTokenInfo = (tokenAddress) => {
    const tokens = usePriceTokens();
    return tokens.get(tokenAddress);
};
