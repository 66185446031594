import { rgba } from 'polished';
import { createGlobalStyle } from 'styled-components';
export const GlobalAppStyle = createGlobalStyle `
  * {
    box-sizing: border-box;
  }

  p {
    margin-block-start: 0px;
    margin-block-end: 0px;
  }

  ul {
    margin-block-start: 0px;
    margin-block-end: 0px;
  }

  body.modal-open {
    height: 100vh;
    overflow-y: hidden;
  }

  /* Notifications */

  :root {
    --toastify-toast-width: auto;
    --toastify-toast-background: ${(props) => props.theme.colors.GR5};
    --toastify-color-light: ${(props) => props.theme.colors.GR5};
    --toastify-font-family: ${(props) => props.theme.fonts.primary};
    --toastify-text-color-light: ${(props) => props.theme.colors.GR2};
  }

  .Toastify {
    .Toastify__toast {
      border-radius: ${(props) => props.theme.borderRadius.xs}px;
      font-weight: 600;
      backdrop-filter: blur(1px);
      box-shadow: rgba(0, 0, 0, 0.5) 8px 8px 4px;
    }

    .Toastify__toast-icon {
      width: 40px;
    }

    .Toastify__toast-body > div:last-child {
      padding: 0 ${(props) => props.theme.spacings.md}px;
    }

    .Toastify__toast-body > div {
      display: flex;
      justify-content: center;
    }
  }

  #near-wallet-selector-modal .nws-modal-wrapper .modal .wallet-options-wrapper .options-list li {
    .wallet-content .wallet-img-box {
      width: 40px;
      flex: 0 1 40px;
      height: 40px;
      margin: 5px 0;
      border-radius: 50%;
    }

    &#math-wallet {
      .wallet-content .wallet-img-box {
        background: black;
      }

      img {
        filter: invert(1);
      }
    }
  }

  /* Wallet connector */
  #near-wallet-selector-modal .light-theme {
    --backdrop-bg: ${(props) => rgba(props.theme.colors.C6, 0.8)};
    --heading-color: ${(props) => props.theme.colors.C1};
    --text-color: ${(props) => props.theme.colors.D7};
    --wallet-selector-home-button-bg: ${(props) => props.theme.colors.B6};
    --wallet-selector-selected-wallet-bg: ${(props) => props.theme.colors.B6};
    --wallet-selector-wallet-option-bg-hover: ${(props) => props.theme.colors.B6};
    --selected-wallet-bg: #A7A7A730;
    --selected-wallet-bg-hover: transparent;
    --wallet-option-border-color: transparent;
    --content-bg: ${(props) => props.theme.colors.C5};
    --wallet-selector-mobile-bottom-section: ${(props) => props.theme.colors.C5};
    --input-border-color-focus: #5F8AFA;
    --box-shadow-color: transparent;
    --dismiss-button-bg-hover: #A7A7A730;
    --dismiss-button-border-color-hover: inherit;
    --confirm-button-color: #FFFFFF;
    --confirm-button-bg: #5F8AFA;
    --confirm-button-bg-hover: #5AA6FF;
    --confirm-button-border-color: #5F8AFA;
    --error: #DB5555;
    --close-button-color: #262626;
    --spinner-color: #676767;
  }

  #near-wallet-selector-modal {

    .modal-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      h2 {
        margin: 0;
        font-size: 20px;
      }
    }
    
    .nws-modal-wrapper {
      font-family: ${(props) => props.theme.fonts.primary};

      .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar .content .title {
        white-space: nowrap;
      }

      .nws-modal .nws-modal-body .alert-message .connection button {
        color: ${(props) => props.theme.colors.GR2};
      }

      .nws-modal-body button.middleButton {
        color: ${(props) => props.theme.colors.GR2};
      }

      .close-button {
        height: 48px;
        line-height: 48px;
        width: 48px;
        text-align: center;
        padding: 0;
        background: ${(props) => props.theme.colors.C4};
        color: ${(props) => props.theme.colors.C3};
        border-radius: ${(props) => props.theme.borderRadius.md}px;

        svg {
          display: none;
        }

        &:before {
          content: 'Esc';
         
        }
      }

      .spinner img {
        width: 70%;
        height: auto;
        padding: 0;
        margin: 15%;
        display: block;
      }

      .modal {
        box-shadow: 0px 15px 30px -20px rgba(11, 58, 137, 0.6);
      }
      
      .modal .wallet-options-wrapper .options-list {
        display: block;
        margin: ${(props) => props.theme.spacings.md}px 0;

        li {
          padding: 0;
          border: 0;
          border-top: 1px solid ${(props) => props.theme.colors.GR3};
          border-radius: 0;

          .wallet-content {
            flex: 1;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            transition: background 0.2s ease-in-out;
           
            color: ${(props) => props.theme.colors.C1};
            font-weight: 600;
            padding: ${(props) => props.theme.spacings.sm}px;
            border-radius: ${(props) => props.theme.borderRadius.md}px;

            img {
              width: 36px;
              height: 36px;
            }
          }

          &: first-child {
            border-top-color: transparent;
          }

          &:hover {
            border-top-color: transparent;
            .wallet-content {
              background: ${(props) => props.theme.colors.C4};
            }

            + li {
              border-top-color: transparent;
            }
          }
        }
      }

      .action-buttons {
        button {
          border: 0;
          border-radius: ${(props) => props.theme.borderRadius.md}px;
          background: ${(props) => props.theme.colors.GR3};
          height: 40px;
          margin: 0;
        }
      }

      .description {
        display: none;
      }
    }
  }
`;
